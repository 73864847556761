import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Logo from "../image/sticker.jpeg";
import Inwardsign1 from "../image/inwardsign1.jpg";
import Inwardsign2 from "../image/sign2.png";
import moment from "moment";
import { Grid, TextField } from "@mui/material";
import { BASE_URL } from "./../../global";
import ExcelDownload from "../../utils/components/excelDownload";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import { toast } from "react-toastify";
import ListWrap from "../commonComponent/listWrap";

const columns = [
  { id: "id", label: "Sr.No.", minWidth: 1 },
  { id: "SRFNoDate", label: "SRF No & Date", minWidth: 10 },
  { id: "DCNoDate", label: "DC No & Date", minWidth: 10 },
  { id: "ulrNo", label: "ULR Number", minWidth: 10 },
  { id: "address", label: "Customer Address", minWidth: 10 },
  { id: "Description", label: "Description", minWidth: 10 },
  {
    id: "makemodel",
    label: "Make / Model",
    minWidth: 10,
    align: "left",
  },
  {
    id: "rangeslc",
    label: "Range / LC",
    minWidth: 10,
    align: "left",
  },
  {
    id: "serialNumberducId",
    label: "SL No / ID No",
    minWidth: 10,
    align: "left",
  },
  {
    id: "ConOfDuc",
    label: "Visual Condition",
    minWidth: 10,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Date of Completion",
    minWidth: 10,
    align: "left",
  },
  {
    id: "billnodate",
    label: "BillNo & date",
    minWidth: 15,
    align: "left",
  },
  {
    id: "Remarks",
    label: "Remarks",
    minWidth: 10,
    align: "left",
  },
];

function createData(
  id,
  SRFNoDate,
  DCNoDate,
  ulrNo,
  address,
  Description,
  makemodel,
  rangeslc,
  serialNumberducId,
  ConOfDuc,
  dueDate,
  companyHierarchy,
  billnodate,
  Remarks
) {
  return {
    id,
    SRFNoDate,
    DCNoDate,
    ulrNo,
    address,
    Description,
    makemodel,
    rangeslc,
    serialNumberducId,
    ConOfDuc,
    dueDate,
    companyHierarchy,
    billnodate,
    Remarks,
  };
}
let notNeededColumn = ["id"];
export default function InwardRegister() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [data, setData] = React.useState("");
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dateData, setDateData] = React.useState([]);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [settingList, setSettingList] = React.useState({});
  const _companyHierarchy = localStorage.getItem("companyHierarchy");
  const printComponentRef = React.useRef();
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);
  const [isPrint, setIsPrint] = React.useState(false);
  const [address, setaddress] = React.useState([]);
  const [searched, setSearched] = React.useState(false);

  const FilterData = () => {
    let resultProductData;

    // Filter by date range and selected companies
    resultProductData = data?.filter((datas) => {
      const isDateInRange =
        new Date(datas.srfs_entryDate) >=
          new Date(fromDate).setHours(0, 0, 0, 0) &&
        new Date(datas.srfs_entryDate) <=
          new Date(toDate).setHours(23, 59, 59, 999);

      const isHierarchyMatched =
        _userType === "3"
          ? _companyHierarchy == datas.srfs_companyHierarchy
          : true;

      return isDateInRange && isHierarchyMatched;
    });

    // Update the state with filtered data
    setDateData(resultProductData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    let endPoint = "";
    endPoint =
      _userType == "3"
        ? `clients/${_clientId}?_where=(status,eq,1)`
        : `clients?_where=(status,eq,1)`;
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const fetchAddresses = async () => {
    return axiosWithToken
      .get(BASE_URL + `addresses`)
      .then((res) => setaddress(res.data))
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };
  console.log({ calData });
  const getData = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          "xjoin?_join=ds.datasheets,_j,client.clients,_j,inst.instruments,_j,srfs.srfs,_j,cert.certificates,_j,srfsinst.srfInstruments&_on1=(ds.clientId,eq,client.id)&_on2=(ds.instrumentId,eq,inst.id)&_on3=(ds.serviceReqNumber,eq,srfs.serviceReqNumber)&_on4=(cert.id,eq,ds.id)&_on5=(cert.id,eq,srfsinst.id)&_fields=client.companyName,client.contact,srfs.address,inst.instrumentName,cert.ULRNo,cert.certificateNumber,cert.approvedDate,ds.calibrationDate,ds.dateOfIssue,client.id,ds.id,ds.lc,ds.DUCID,ds.make,ds.model,ds.ranges,srfs.companyHierarchy,srfs.entryDate,srfs.serviceReqNumber,srfsinst.ConOfDuc,srfs.dcDate,srfs.dcNumber,ds.accuracy,ds.serialNumber"
      )
      .then(async (res) => {
        const fetchedData = res.data;

        setData(fetchedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const formatDate = (date) => {
    return date ? moment(date).format("DD-MM-YYYY") : "";
  };
  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < dateData.length; i++) {
      idAr.push(dateData[i].ds_id);
      rowData.push(
        createData(
          dateData[i].ds_id,
          `${
            dateData[i].srfs_serviceReqNumber
              ? dateData[i].srfs_serviceReqNumber
              : ""
          }\n${formatDate(dateData[i].srfs_entryDate)}`,
          `${
            dateData[i].srfs_dcNumber ? dateData[i].srfs_dcNumber : ""
          }\n${formatDate(dateData[i].srfs_dcDate)}`,
          dateData[i].cert_ULRNo,
          `${dateData[i].client_companyName}\n${address.find((el) => el.id == dateData[i].srfs_address?.split(",")[0])
            ?.address || ""}`,
          dateData[i].inst_instrumentName,
          `${dateData[i].ds_make} / ${dateData[i].ds_model}`,
          `${dateData[i].ds_ranges
            ?.replaceAll("|", ", ")
            ?.replaceAll("#", " ")} / ${dateData[i].ds_lc
            ?.replaceAll("|", ", ")
            ?.replaceAll("#", " ")}`,
          `${dateData[i].ds_serialNumber} / ${dateData[i].ds_DUCID}`,
          dateData[i].srfsinst_ConOfDuc,
          formatDate(dateData[i].cert_approvedDate),
          dateData[i].srfs_companyHierarchy
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
        let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(err.message);
        }
      });
  }

  const generateDynamicHeader = async () => {
    let htmlString = `
    <div style="width:100%;margin:auto;padding:0px 29px 0px 31px;">
    <div style="border: 1px solid black;">
        <div style="text-align: left; padding: 5px 0;background:white;">
            <img src=${Logo} height="60px" alt="Logo" />
        </div>
        <div style="text-align: center; padding: 5px 0; border-top: 1px solid black; border-bottom: none;">
            <h6 style="font-size:1.2rem;"><b>Incoming Register Format</b></h6>
        </div>
    </div>
       </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async () => {
    let htmlString = `
    <div style="width:100%;margin:auto;padding:30px 29px 30px 31px;">
    <table style="width:100%;">
        <tr>
        <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;">
                <b style="font-size:13px;">Prepared & Issued By : QM</b>
            </td>
             <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;">
                <b style="font-size:13px;">Approved By : CMD</b>
            </td>
        <tr>
        <tr>
        <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;">
                <b style="font-size:13px;">Name :  S Muthukumaran </b>
            </td>
             <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;">
                <b style="font-size:13px;">Name : A Sashikumar</b>
            </td>
        <tr>
        <tr>
        <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;background:white;">
                <b style="font-size:13px;">Signature :  <img src=${Inwardsign1} style="width:80px; height:30px;"/> </b>
            </td>
             <td style="padding:1px 5px;width:50%;text-align: left;font-family: Calibri, sans-serif;background:white;">
                <b style="font-size:13px;">Signature :  <img src=${Inwardsign2} style="width:80px; height:30px;"/></b>
            </td>
        <tr>
        </table>
        <div style="text-align: left;">
        <b style="font-size:12px;">PTCCPL/7.4/FF/01</b>
         <b></div>
       </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [82, 1, 74, 1],
          filename: `masterInOut.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "landscape",
            unit: "pt",
            format: "a4",
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("helvetica", "bold");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 23;
        const borderX = leftMargin;
        const borderY = 80;
        const rightMargin = 840 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 445);

        let image = await generateDynamicHeader();
        pdf.addImage(image, 0, 5, 840, 70);
        
        image = await generateDynamicFooter();
        pdf.addImage(image, 0, 515, 840, 90);

      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `inwardregister.pdf`;
      a.click();

      setDownlaodPdfLoading(false);

      refresh();
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  var refresh = () => {
    window.location.reload(false);
  };
  const search = () => {
    setSearched(true);
    FilterData();
  };
  const clearSearch = () => {
    setSearched(false);
    setFromDate("");
    setToDate("");
    setDateData([]);
  };

  const handleBillnoChange = (id, value) => {
    setCalData((prev) =>
      prev.map((row) => (row.id === id ? { ...row, billnodate: value } : row))
    );
  };

  const handleRemarksChange = (id, value) => {
    setCalData((prev) =>
      prev.map((row) => (row.id === id ? { ...row, Remarks: value } : row))
    );
  };

  useEffect(() => {
    getCustomer();
    fetchSettings();
    fetchAddresses();
    getData();
  }, []);

  useEffect(() => {
    initializeRowTable();
  }, [dateData]);

  return (
    <ListWrap>
        <Grid
          container
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <TextField
              id="Start Date"
              label="Date From"
              variant="outlined"
              type="date"
              className="Listinputcss"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                setSearched(false);
              }}
            />
          </Grid>

          <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <TextField
              id="Start Date"
              label="Date To"
              variant="outlined"
              type="date"
              className="Listinputcss"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={1} textAlign={"left"}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              color={searched ? "error" : "primary"}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          {ids.length > 0 && (
            <>
              <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
                <ExcelDownload
                  finalData={calData}
                  notNeededColumn={notNeededColumn}
                />
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={2} textAlign={"left"}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setIsPrint(true);
                    generatePDF();
                  }}
                >
                  Download PDF
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <TableContainer>
          <div
            style={{
              width: isPrint ? "95%" : "100%",
              margin: "auto",
              padding: isPrint ? "5px 7px" : "0px",
            }}
            ref={printComponentRef}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              style={{
                borderCollapse: "collapse",
                marginTop: isPrint ? "0px" : "5px",
              }}
            >
              <TableBody className="single-border-table">
                <TableRow>
                  {columns?.map((column) =>
                    !settingList["companyHierarchy"] &&
                    column.id === "companyHierarchy" ? null : (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          width: `${column.minWidth}%`,
                          fontSize: isPrint ? "9.2px" : "12px",
                          padding: isPrint ? "1px 6px" : "6px 16px",
                        }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    )
                  )}
                </TableRow>
                {calData?.length > 0 ? (
                  calData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          id="pagbreaksborder"
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          style={{ pageBreakInside: "avoid" }}
                        >
                          {columns?.map((column) => {
                            let value = row[column.id];

                            if (column.id === "id") {
                              value = page * rowsPerPage + index + 1;
                            }

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  fontSize: isPrint ? "9.2px" : "12px",
                                  padding: isPrint ? "1px 6px" : "6px 6px",
                                }}
                              >
                                {isPrint ? (
                                  column.id === "billnodate" ||
                                  column.id === "Remarks" ? (
                                    value
                                  ) : column.format &&
                                    typeof value === "number" ? (
                                    column.format(value)
                                  ) : (
                                    value
                                  )
                                ) : column.id === "billnodate" ? (
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    value={row.billnodate || ""}
                                    onChange={(e) =>
                                      handleBillnoChange(row.id, e.target.value)
                                    }
                                    style={{ width: "100%" }}
                                  />
                                ) : column.id === "Remarks" ? (
                                  <TextField
                                    variant="standard"
                                    size="small"
                                    value={row.Remarks || ""}
                                    onChange={(e) =>
                                      handleRemarksChange(
                                        row.id,
                                        e.target.value
                                      )
                                    }
                                    style={{ width: "100%" }}
                                  />
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} align="center">
                      <h6>
                        <b>Data Not Found</b>
                      </h6>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div style={{ width: "100%" }} id="header-section"></div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={calData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Summary Report"}
          />
        )}

    </ListWrap>
  );
}
