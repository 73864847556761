import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import moment from "moment";
import {
  Grid,
  Paper,
  TextField,
  Autocomplete,
  Button,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";
import { viewsrfGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import axiosWithToken from "../../utils/components/axiosTokenConfig";


export default function PrintSrf() {
  const viewsrfGuides = viewsrfGuide();
  const printComponentRef = React.useRef();
  const params = useParams();
  const [clientArray, setClientArray] = React.useState([]);
  const [srfInstruments, setSrfInstruments] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  var [allInstruments, setInstruments] = React.useState([]);
  const [letterHead, setLetterHead] = React.useState(true);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  // const [electronicSign, setElectronicSign] = React.useState(true);

  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [commitedDate, setCommitedDate] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [clientID, setClientID] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [srfsData, setSRFsData] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [pushArray, setPushArray] = React.useState([]);
  const [newAddressArray, setNewAddressArray] = React.useState([]);
  const [downloadPdfloading, setDownlaodPdfLoading] = React.useState(false);

  function fetchSrfDetails() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfs?_where=(id,eq,${params.id})`)
      .then((res) => {
        setServiceReqNumber(res.data[0]?.serviceReqNumber);
        setDcDate(res.data[0]?.dcDate);
        setReceiptDate(res.data[0]?.receiptDate);
        setEntryDate(res.data[0]?.entryDate);
        setCommitedDate(res.data[0]?.committedDeliveryDate);
        setDcNumber(res.data[0]?.dcNumber);
        setClientID(res.data[0]?.clientId);
        setAddress(res.data[0]?.address);
        setSRFsData(res.data[0]);
        setNewAddressArray(res.data[0]?.contactPersonDetails ? JSON.parse(res.data[0]?.contactPersonDetails) : null);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }

  const getClientId = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `clients?_where=(id,eq,${clientID})`)
      .then((res) => {
        setClientArray(res.data);
        setCompanyName(res.data[0]?.companyName);
      })
      .catch((error) => {
        console.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const instrumentsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      rows.push([srfInstruments[i].id, srfInstruments[i].instrumentId]);
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    // console.log(rows);
  };

  const generatePushArray = (srfInstruments, allInstruments) => {
    const newPushArray = [];

    for (let i = 0; i < srfInstruments.length; i++) {
      newPushArray.push({
        instrumentId: srfInstruments[i].requestedDucName ? srfInstruments[i].requestedDucName : allInstruments.find(
          (element) => element.id === srfInstruments[i].instrumentId
        )?.instrumentName,
        ranges: srfInstruments[i].ranges,
        lc: srfInstruments[i].lc,
        make: srfInstruments[i].make,
        conOfReceipt: srfInstruments[i].conOfReceipt,
        calFrequency: srfInstruments[i].calFrequency,
        accuracy: srfInstruments[i].accuracy,
        model: srfInstruments[i].model,
        serialNumber: srfInstruments[i].serialNumber,
        DUCID: srfInstruments[i].DUCID,
        calPoint: srfInstruments[i].calPoint,
        ConOfDuc: srfInstruments[i].ConOfDuc,
        calFrequency: srfInstruments[i].calFrequency,
      });
    }

    return newPushArray;
  };

  useEffect(() => {
    setPushArray(generatePushArray(srfInstruments, allInstruments));
  }, [srfInstruments, allInstruments]);

  useEffect(() => {
    srfInstrumentsList();
    instrumentsList();
    fetchSrfDetails();
  }, []);

  useEffect(() => {
    getClientId();
  }, [clientID]);

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let div = document.createElement("div");
    div.innerHTML = htmlString;
    document.body.appendChild(div);
    let canvas = await html2canvas(div, { scale: 1.3 });
    const img = new Image();
    img.src = canvas.toDataURL("image/jpeg", 0.90);
    document.body.removeChild(div);
    return img;
  };

  const generateDynamicHeader = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          <td style="border:none;padding:5px;font-family: Calibri, sans-serif;height:60px;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, pixelRatio: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [95, 1, 80, 1],
          filename: `srf${params.id}.pdf`,
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 2.5,
            letterRendering: true,
            useCORS: true,
          },
          image: { type: 'png', quality: 0.4 },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15;
        const borderX = leftMargin;
        const borderY = 90;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 670);

        let image = await generateDynamicHeader(i, totalPages);
        pdf.addImage(image, 0, 53, 595, 60);

        if (letterHead) {
          let image = await generateLetterHeadHeader();

          pdf.addImage(image, 0, 0, 595, 70);

          image = await generateLetterHeadFooter();
          pdf.addImage(image, 0, 772, 595, 70);
        }

        if (i === totalPages) {
          const text =
            "This SRF is electronically signed and does not required physical signature.";
          const textWidth =
            (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
            pdf.internal.scaleFactor;
          const pageWidth = pdf.internal.pageSize.getWidth();
          const x = (pageWidth - textWidth) / 2;
          {
            letterHead && pdf.text(text, x, 768);
          }
        }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${companyName}_${serviceReqNumber}.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  useEffect(async () => {
    let splitAddress = address?.split(",");
    let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
    // setNewAddressArray(newAddressArray);
  }, [address]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              viewsrfGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2} align="left" style={{ display: "flex" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="srf-viewsrf_letterhead"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
              // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            id="srf-viewsrf_download"
            variant="contained"
            size="small"
            sx={{ ml: 3 }}
            disabled={!(editAccess?.includes(3) || editAccess?.includes(0))}
            onClick={() => {
              generatePDF();
            }}
          >
            Download PDF
          </Button>
        </Grid>
      </Grid>
      <br />
      <div className="srf" ref={printComponentRef} id="srf-viewsrf_print">
        <div
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            lineHeight: "16px",
          }}
        >
          <div  style={{
            width: "101%",margin: "0 auto",
          }}>
          <div className="row" style={{ lineHeight: "16px" }}>
            <div className="col-2"></div>
            <div className="col-5 mt-1">
              <h4 className="font-bold text-center">SERVICE REQUEST FORM</h4>
            </div>
            <div className="col-5 mt-1">
              <div style={{width:"90%"}}>
              <table  className="standardUnitTable single-border-table">
                <tr>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                  width: "50%"
                }}>Format No</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                  width: "1%"
                }}>:</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                  width: "49%"
                }}>PTCCPL/7.1/FF/01</td>
                </tr>
                <tr>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>Issue No</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>:</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>03</td>
                </tr>
                <tr>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>Issue Date</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>:</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>17.04.2024</td>
                </tr>
                <tr>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>Revision No</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>:</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>00</td>
                </tr>
                <tr>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>Revision Date</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>:</td>
                <td style={{
                  fontSize:"12px",
                  textAlign:"left",
                  padding:"0px 3px",
                }}>--</td>
                </tr>
              </table>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div
          className="srfFulfilled"
          style={{
            width: "95%",
            margin: "0 auto",
            // border: "1px solid black",
            borderTop: "none",
            lineHeight: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: "11px", paddingBottom: "5px", marginTop:"7px" }}>
            <table
              style={{ width: "95%", margin: "0px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  Client
                </th>
                <td
                  rowSpan={"2"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  {companyName}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  WO-PO No.
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {" "}
                  {srfsData?.poNumber}
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    padding: "7px",
                    borderRight: "none",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  {moment(srfsData?.poDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  Address
                </th>
                <td
                  colSpan={"3"}
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                  }}
                >
                  {newAddressArray?.address}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  GST No
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {clientArray[0]?.gstNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Customer Name
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {newAddressArray?.contactPersonName}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Email
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {newAddressArray?.emailId}
                </td>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Phone Number
                </th>
                <td style={{ padding: "7px", width: "250px" }}>
                  {newAddressArray?.contact}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "10px auto" }}
              className="srftable"
            >
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  SRF Number
                </th>
                <td
                  style={{
                    width: "25%",
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {serviceReqNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  Date of Receipt
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                  }}
                >
                  {moment(receiptDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Number
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {dcNumber}
                </td>
                <th
                  style={{
                    padding: "7px",
                    borderBottom: "none",
                    borderRight: "none",
                    width: "25%",
                  }}
                >
                  DC Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "250px",
                    borderBottom: "none",
                    width: "25%",
                  }}
                >
                  {moment(dcDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr style={{ textAlign: "left" }}>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Entry Date
                </th>
                <td
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  {moment(entryDate).format("DD-MM-YYYY")}
                </td>
                <th
                  style={{
                    padding: "7px",
                    width: "25%",
                    borderRight: "none",
                  }}
                >
                  Calibration to be Completed on
                </th>
                <td style={{ padding: "7px", width: "250px" }}>
                  {moment(commitedDate).format("DD-MM-YYYY")}
                </td>
              </tr>
            </table>
            <table
              style={{ width: "95%", margin: "auto" }}
              className="srftable"
            >
              <thead>
                <tr>
                  <th
                    colSpan={11}
                    style={{ padding: "5px", borderBottom: "none" }}
                  >
                    INSTRUMENT DETAILS
                  </th>
                </tr>
                <tr>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    SI.No
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Description of the Equipment
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Make
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Model
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Serial Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Identification Number
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Range
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Calibration Points If any
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Declared Accuracy / Acceptance Criteria
                  </th>
                  <th
                    style={{
                      padding: "7px",
                      borderRight: "none",
                    }}
                  >
                    Requested Due Date
                  </th>
                  <th style={{ padding: "7px" }}>Condition of UUC / Remarks</th>
                </tr>
              </thead>
              {pushArray.length > 0 ? (
                <tbody>
                  {pushArray.map((item, index) => {
                    // console.log(item, "item");
                    return (
                      <tr style={{ lineHeight: "13px" }} id="pagbreaksborder">
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.instrumentId}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.make
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.model}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.serialNumber}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.DUCID}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.ranges
                            ?.replaceAll("||", ", ")
                            ?.replaceAll("|", " to ")
                            ?.replaceAll("#", " ")}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calPoint}{" "}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.accuracy
                            ? `± ${item.accuracy
                                ?.replaceAll("||", ", ")
                                ?.replaceAll("|", " to ")
                                ?.replaceAll("#", " ")}`
                            : ""}
                        </td>
                        <td
                          style={{
                            padding: "7px",
                            borderRight: "none",
                            borderTop: "none",
                          }}
                        >
                          {item.calFrequency}
                        </td>
                        <td style={{ padding: "7px", borderTop: "none" }}>
                          {item.ConOfDuc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="11">No items added</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        <div style={{ width: "100%" }} id="header-section"></div>
      </div>

      <ToastContainer />
      {downloadPdfloading && (
        <DownloadPdfLoaderModal loading={downloadPdfloading} name={"SRF"} />
      )}
    </Paper>
  );
}